


































import { Component, Mixins, Prop } from 'vue-property-decorator';

import Tab from '@/components/Subject/Tab/index.vue';
import MaterialsTab from '@/components/TabsAccordion/MaterialTab/index.vue';
import VideosTab from '@/components/TabsAccordion/VideosTab/index.vue';

import HistoricService from '@/services/Historic/historicService';

import accordionTab from '@/mixins/accordionTab';
import generalBooks from '@/mixins/generalBooks';

import { TypeMaterial } from '@/services/Historic/historicInterface';

@Component({
  mixins: [accordionTab],
  components: {
    Tab,
    MaterialsTab,
    VideosTab
  }
})
export default class SubjectPerformance extends Mixins(generalBooks) {
  @Prop() topic!: Record<string, any>;
  @Prop() bookId!: number;

  private allChecked: Record<string, any> = {
    videos: [],
    materials: []
  };
  private ids: Record<string, any> = {
    videos: [],
    archives: []
  };

  private HistoricService = new HistoricService();

  created() {
    this.loaderData();
  }

  async loaderData() {
    this.getIds(this.topic, 'videos', 'idVideo');
    this.getIds(this.topic, 'archives', 'idArchive');

    await this.getAllFilesChecked(this.ids.videos, 'video', 'videos');
    await this.getAllFilesChecked(this.ids.archives, 'materiais', 'materials');
  }

  getIds(data: Record<string, any>, param: string, idType: string) {
    if (!data.topics || data.topics.length <= 0) {
      if (data[param] && data[param].length > 0) {
        const ids = data[param].map((item: Record<string, any>) => item[idType]);
        this.ids[param] = [...this.ids[param], ...ids];
      }
    } else {
      data.topics.forEach((topic: Record<string, any>) => {
        this.getIds(topic, param, idType);
      });
    }
  }

  async getAllFilesChecked(
    ids: Array<number>,
    historicParam: TypeMaterial,
    allChechedParam: string
  ) {
    if (ids && ids.length > 99) {
      const response = await this.getHistoric(historicParam, ids.slice(0, 100));

      this.allChecked[allChechedParam].push(...response);
      await this.getAllFilesChecked(ids.slice(100), historicParam, allChechedParam);
    } else if (ids && ids.length) {
      const response = await await this.getHistoric(historicParam, ids);

      this.allChecked[allChechedParam].push(...response);
    }
  }

  async getHistoric(type: TypeMaterial, listIds: Array<number>) {
    try {
      const response = await this.HistoricService.getHistoric({ type, listIds });

      return response || [];
    } catch (error) {
      return [];
    }
  }

  updateAllChecked(event: number) {
    const newAllChecked: Record<string, any> = this.allChecked;
    newAllChecked.materials.push({
      idobject: event,
      checked: true
    });

    this.allChecked = newAllChecked;
  }

  setData(item: Record<string, any>, type: string) {
    const { context, path } = this.$route.params;

    if (type === 'video') {
      return {
        to: '/videos',
        from: `${context}/${path}`,
        file: item,
        id: item.idVideo
      };
    }

    if (type === 'material') {
      return {
        to: '/materiais',
        from: `${context}/${path}`,
        file: item,
        id: item.idArchive
      };
    }

    return '';
  }
}
