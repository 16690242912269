
















































import { Component, Watch, Mixins } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import SubjectInfo from '@/components/Subject/SubjectInfo/index.vue';
import SubjectBox from '@/components/Subject/SubjectBox/index.vue';
import ListAllBooks from '@/components/ListAllBooks/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import Footer from '@/components/Footer/Footer.vue';

import ExerciseResultPerformanceLoading from './ExerciseResultPerformanceLoading.vue';
import SubjectPerfomance from './components/SubjectPerfomance/SubjectPerfomance.vue';

import CourseData from '@/mixins/CourseData';
import PlannerData from '@/mixins/PlannerData';
import BookData from '@/mixins/BookData';

import { LIST_NAME_CONTEXT } from '@/constant/Context';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    BoxContainer,
    SubjectBox,
    SubjectInfo,
    SubjectPerfomance,
    ListAllBooks,
    Footer,
    FeedbackUser,
    ExerciseResultPerformanceLoading
  }
})
export default class ExerciseResultPerformance extends Mixins(CourseData, PlannerData, BookData) {
  private isLoading = true;

  private topic: Record<string, any> = {};

  private ids: Record<string, any> = {
    videos: [],
    archives: []
  };

  async mounted() {
    await this.getBook();
    this.setBreadCrumbs();
    await this.getTopic();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get showBooks() {
    const { context } = this.$route.params;
    return context === LIST_NAME_CONTEXT.BOOK;
  }

  async getTopic() {
    try {
      this.isLoading = true;
      const { context } = this.$route.params;

      if (context === LIST_NAME_CONTEXT.PLANNER) {
        this.loaderPlannerData();
        return;
      }

      if (context === LIST_NAME_CONTEXT.COURSE) {
        await this.loaderCourseData();
        return;
      }

      await this.loaderBookData();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do tópico.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  // A  função abaixo são responsavel por tratar os dados de caderno e atribuir a variavel topic
  async loaderBookData() {
    try {
      this.topic = await this.getTopicBook();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do caderno.',
        status: 'error'
      });
      console.error(error);
    }
  }

  async loaderPlannerData() {
    try {
      const plannerData = await this.setTopicPlanner();
      // continuar a logica quando ativar exercicio no planner
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do planner.',
        status: 'error'
      });
      console.error(error);
    }
  }

  async loaderCourseData() {
    try {
      const { path, topicID } = this.$route.params;

      const splitPath = path.split('&');
      const idCourses = splitPath[1];
      const idClass = splitPath[2];
      const weekID = splitPath[3];

      const response = await this.CoursesService.getClassWeekCourse(idCourses, idClass);

      if (response && response.length) {
        const currentCourseWeek = this.filterWeekCourse(response, Number(weekID));

        const topic = this.searchTopicCourse(currentCourseWeek, Number(topicID));

        await this.loadFilesCourse(topic);

        this.topic = this.setFilesCourse(topic);
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os dados do curso.',
        status: 'error'
      });
      console.error(error);
    }
  }

  async loadFilesCourse(topic: Record<string, any>) {
    this.getIdsCourse(topic, 'videos');
    this.getIdsCourse(topic, 'archives');

    await this.getFilesVideos(this.ids.videos, 'videos');
    await this.getFilesMaterials(this.ids.archives, 'archives');
  }

  setListInfo(topic: Record<string, any>) {
    let quantityAulas = 0;
    let quantityArchives = 0;

    if (topic?.archives && topic?.archives.length) {
      quantityArchives = topic?.archives.length;
    }

    if (topic?.videos && topic?.videos.length) {
      quantityAulas = topic?.videos.length;
    }

    return [
      {
        quantity: quantityAulas,
        title: 'Aulas'
      },
      {
        quantity: quantityArchives,
        title: 'Apostilas'
      }
    ];
  }

  setBreadCrumbs() {
    const {
      context, path, topicID, fileID, slugFile 
    } = this.$route.params;

    if (context === 'planner') {
      this.setBreadCrumbsPlanner();
      return;
    }

    if (context === 'cursos') {
      this.setBreadCrumbsCourse();
      return;
    }

    this.$breadcrumb.set([
      { title: 'cadernos', to: '/cadernos' },
      { title: this.currentBook?.title, to: `/caderno/${path}` },
      {
        title: 'resultado',
        to: `/exercicios/${context}/${path}/${topicID}/${fileID}/${slugFile}/resultado`
      },
      { title: 'desempenho', to: null }
    ]);
  }

  setBreadCrumbsPlanner() {
    const {
      context, path, topicID, fileID, slugFile 
    } = this.$route.params;

    this.$breadcrumb.set([
      { title: 'planner', to: '/planner' },
      {
        title: 'resultado',
        to: `/exercicios/${context}/${path}/${topicID}/${fileID}/${slugFile}/resultado`
      },
      { title: 'desempenho', to: null }
    ]);
  }

  setBreadCrumbsCourse() {
    const {
      context, path, topicID, fileID, slugFile 
    } = this.$route.params;

    const splitPath = path.split('&');
    const slugCourse = splitPath[0];
    const idCourses = splitPath[1];
    const idClass = splitPath[2];

    this.$breadcrumb.set([
      { title: 'cursos', to: `/cursos/${slugCourse}/${idCourses}/${idClass}` },
      {
        title: 'resultado',
        to: `/exercicios/${context}/${path}/${topicID}/${fileID}/${slugFile}/resultado`
      },
      { title: 'desempenho', to: null }
    ]);
  }
}
